.reportClass{
  height: calc(100vh - 100px);
}
body {
  background: #f2f2f2 !important;
  font-family: "Roboto",Helvetica,Arial,sans-serif;
  color: #12133E !important;
}
.App {
  color: #12133E;
}
a:hover {
  color: #4054b2 !important;
  text-decoration: none;
}

a {
  color: #4054b2 !important;
  text-decoration: none;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.survey-name {
  display: inline-block;
}
.t4-home p.t4-txt {
  color: #434242;
  width: 90%;
  text-align: center;
  line-height: 1.8em;
}
.t4-lower-bracket {
  margin-top: -20px;
    margin-bottom: 25px !important;
}
form.t4-login-form, form.t4-reset-form {
  text-align: left;
}
.t4-login-form .ui.input, .t4-reset-form .ui.input {
  width: 100%;
  margin: 12px 0;
  box-sizing: border-box;
  text-align: left;
}
.t4-login-form label {
  color: #12133E;
  font-size: 1.2rem;
  font-weight: 700;
}
.t4-reset-form label {
  color: #12133E;
  font-size: .92857143em !important;
  font-weight: 700;
}
ul {
  list-style: none;
}
ul.text-ul {
  list-style-type: square;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.ui.button.floating.labeled.dropdown.language-btn {
  float: right !important;
  top: 37px;
  z-index: 999;
  color: #282c34 !important;
}
.ui.floating.dropdown.language-btn .menu.lang-dd {
  left: auto;
  right: 0;
}
.ui.button.floating.labeled.dropdown.language-btn i.flag.langflag {
  margin-left: 14px;
  margin-top: 3px;
}
.lang-dd button.ui.button {
  background-color: transparent !important;
  padding: 5px !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.t4-content-wrapper, t4-login-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 960px;
}
/*.t4-home {
    position: relative;
    width: calc(100% - 1px);
    padding: 44px;
    margin-bottom: 28px;
    background-color: #fff;
    /*-webkit-box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    -moz-box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    min-width: 320px;
    min-height: 338px;
    overflow: hidden;
    min-height: 500px;
}*/
.t4-home {
  position: fixed;
  width: calc(100% - 1px);
  /*padding: 44px;*/
  margin-bottom: 28px;
  background-color: #E0E8F8;
  
  /*-webkit-box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  -moz-box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);*/
  min-width: 320px;
  min-height: 338px;
  overflow: hidden;
  min-height: 500px;
  left: 0px !important;
  top: 0px !important;
  height: 100% !important;
}
img.ui.left.floated.image.t4-login-img {
  left: 0px !important;
  bottom: 0px !important;
  position: fixed;
}
img.ui.left.floated.image.t4-login-logo {
  z-index: 999;
}
img.ui.right.floated.image.t4-login-logo-bottom {
  right: 35px !important;
  bottom: 35px !important;
  position: fixed;
}
/*.t4-parallelogram {
  position: absolute;
	width: 40px;
    height: 40px;
    background: conic-gradient(at 50% 50%,transparent 135deg,#12133E 0,#12133E 225deg, transparent 0);
  right: 0px;
}
.t4-parallelogram {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  margin: 10px;
  right: 0;
}
.t4-parallelogram:after {
  content: '';
  background: #12133E;
  width: 148%;
  height: 142%;
  position: absolute;
  transform: rotate(128deg) translate(-25%,0);
}*/
.t4-parallelogram {
  background: url(/public/assets/t4-bg.svg) right bottom no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
}
.t4-home .home-segment.nostyle {
  background-color: #E0E8F8 !important;
  height: 100% !important;
  padding: 0px !important;
  margin-top: 15px !important;
}
.segment.nostyle {
  border: none !important;
  box-shadow: none !important;
}
.t4-login-segment {
  border-radius: 0.5rem !important;
  z-index: 999 !important;
}
.ui.segment.t4-login-segment {
  margin-top: 50px !important;
  padding: 60px;
}
p.t4-survey-main-title {
  font-size: 3rem;
  font-weight: bolder;
  color: rgb(19,20,62) !important;
  /*-webkit-text-shadow: 0 2px 1px rgba(58,110,181,1) !important;
  -moz-text-shadow: 0 2px 1px rgba(58,110,181,1) !important;
  text-shadow: 2px 1px rgba(58,110,181,1) !important;*/
  margin-bottom: 0px !important;
}
.t4-action-btn.t4-surv {
  margin: 20px 0px 20px 12px;
  display: block;
}
.t4-action-btn {
  margin: 20px 0px 20px 12px;
  display: contents;
}
.t4-btn-active, .labeled.t4-btn-active  {
  background: rgb(19,20,62) !important;
  background: linear-gradient(90deg, rgba(19,20,62,1) 0%, rgba(58,88,161,1) 100%) !important;
  color: #fff !important;
  -webkit-box-shadow: 0 2px 6px rgba(58,110,181,1) !important;
  -moz-box-shadow: 0 2px 6px rgba(58,110,181,1) !important;
  box-shadow: 0 2px 6px rgba(58,110,181,1) !important;
}
.banner1 img.ui.small.left.floated.image {
  padding: 15px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*Survey UI*/
.t4-content-wrapper {
  position: relative;
  width: calc(100% - 40px);
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  min-width: 320px;
  min-height: 338px;
  /*overflow: hidden;*/
  top: 20px;
}
img.ui.image.survey-banner {
  width: 100% !important;
}
.t4-survey-wrapper .ui.segment {
  border: none !important;
  box-shadow: none !important;
}
.t4-btn {
  background: rgb(19,20,62);
  background: linear-gradient(135deg, rgba(19,20,62,1) 0%, rgba(58,88,161,1) 100%) !important;
  color: #fff !important;
  border-radius: .28571429rem;
  padding: 10px 40px !important;
}
.t4-action-btn .ui.button {
  background: rgb(19,20,62);
  background: linear-gradient(135deg, rgba(19,20,62,1) 0%, rgba(58,88,161,1) 100%) !important;
  color: #fff !important;
  border-radius: .28571429rem;
  padding: 10px 40px !important;
}
.t4-action-btn .ui.button.disabled {
  opacity: .45 !important;
  background: rgb(19,20,62);
  background: linear-gradient(135deg, rgba(19,20,62,1) 0%, rgba(58,88,161,1) 100%) !important;
  color: #fff !important;
}
.t4-action-btns button.ui.button.t4-btn.t4-create.basic {
  padding: 10px 20px !important;
}
.t4-action-btns button.ui.button.t4-btn.t4-create.basic:hover {
  background: rgb(19,20,62);
  background: linear-gradient(135deg, rgba(19,20,62,1) 0%, rgba(58,88,161,1) 100%) !important;
  color: #fff !important;
  border-radius: .28571429rem;
  padding: 10px 20px !important;
}
.t4-btn.ui.basic.button {
  background: transparent none!important;
    font-weight: 400;
    border-radius: .28571429rem;
    text-transform: none;
    text-shadow: none!important;
    box-shadow: 0 0 0 1px rgba(19,20,62,1) inset !important;
  color: #12133E !important;
  border-radius: .28571429rem;
  padding: 10px 25px !important;
}
.t4-btn.ui.button:disabled {
  opacity: 0.20!important;
}
.t4-btn.t4-pop-btn.ui.button:disabled {
  opacity: 0.5!important;
    background: linear-gradient(135deg, rgba(19,20,62,1) 0%, rgba(58,88,161,1) 100%) !important;
}
.t4-confirm-popup .t4-confirm-1 {
  margin-bottom: 15px;
}
.t4-action-btn > .t4-btn-surv.prev {
  margin-right: 15px;
}
/*.t4-popup-btns button.ui.button {
  margin-right: 15px !important;
}*/
/*Flip Phone*/
/*.t4-questions > div:first-child > .flip-phone-viewer > .likert-main-header, .t4-questions > div:first-child .likert-main-header,
.t4-questions div.quest-type2:first-of-type > .flip-phone-viewer .likert-main-header
{
  display: initial !important;
}*/
.t4-likert-options.likert-tbl .t4-likert-choices li.emojie-option {
  text-align: center !important;
  margin: 0 auto;
}
.t4-questions > div:first-child .likert-main-header,
div.quest-type2:first-of-type > .flip-phone-viewer .likert-main-header, div:not(.quest-type2) + .quest-type2 .flip-phone-viewer .likert-main-header
{
  display: initial !important;
}
div.quest-type2:first-of-type {
  background-color: #12133E !important;
}
table.ui.striped.table.t4-likert-options {
  margin-top: 0px !important;
}
.ui.segment.t4-likert {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 0px !important;
  border-radius: 0px !important;
}
.t4-questions > div:nth-child(2n)  .flip-phone-viewer > .t4-likert .t4-likert-options tr.likert-emo {
  background-color: #dddddd;
}
.t4-likert .t4-likert-options.t4-likert-head tr.likert-emo {
  background-color: #fff !important;
}
.t4-likert-options tr.t4-req {
  background-color: transparent !important;
  padding: 0px !important;
}
.flip-phone .flip-text {
  padding: 0px 15px;
}
/*.t4-questions > div:first-child > .flip-phone-viewer {
  display: none;
}*/
/********Question Types********/
/*MCQ Radio Buttons*/
.t4-mcq-radio {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
.t4-mcq-radio .ui.radio.checkbox {
  display: list-item;
  text-align: -webkit-match-parent;
  list-style: none;
  padding-bottom: 3px;
}
.ui.radio.checkbox .box:before, .ui.radio.checkbox label:before, 
.ui.checkbox .box:before, .ui.checkbox label:before {
  border: 2px solid #B8B8B8 !important;
  background-color: #E5E5E5 !important;
}
.ui.radio.checkbox input:checked~.box:before, .ui.radio.checkbox input:checked~label:before, 
.ui.checkbox input:checked~.box:before, .ui.checkbox input:checked~label:before {
  background-color: #263670 !important;
  border: 3px solid #263670 !important;
}
.emojie-option .ui.radio.checkbox .box:after, .emojie-option .ui.radio.checkbox label:after {
  background-color: transparent !important;
}
.t4-likert-head .emojie-option .ui.radio.checkbox .box, .t4-likert-head .emojie-option .ui.radio.checkbox label {
  margin-top: -13px;
  padding-left: 0px !important;
}
.emojie-option .ui.radio.checkbox .box, .emojie-option .ui.radio.checkbox label {
  margin-top: -13px;
}
.emojie-option .ui.radio.checkbox input:checked~.box:after, .emojie-option .ui.radio.checkbox input:checked~label:after {
  background-color: transparent !important;
}
.t4-likert-head .emojie-option .ui.radio.checkbox .box:before, .t4-likert-head .emojie-option .ui.radio.checkbox label:before {
  display: none;
}
.emojie-option.worst .ui.radio.checkbox .box:before, .emojie-option.worst .ui.radio.checkbox label:before
 {
  /*background-image: url(/public/assets/worst.svg);
  border: none !important;
  background-color: transparent !important;
  width: 40px !important;
  height: 40px !important;*/
  background: url(/public/assets/likert-emojies.png)  no-repeat !important;
  background-position: 0px 0px;
  width: 40px;
  height: 40px;
  border: none !important;
}
.emojie-option.worst .ui.radio.checkbox input:checked~.box:before, .emojie-option.worst .ui.radio.checkbox input:checked~label:before,
.emojie-option.worst .ui.checkbox input:checked~.box:before, .emojie-option.worst .ui.checkbox input:checked~label:before
 {
  /*background-image: url(/public/assets/worst-active.svg);
  border: none !important;
  background-color: transparent !important;*/
  background: url(/public/assets/likert-emojies.png)  no-repeat !important;
  background-position: -246px 0px !important;
  width: 40px;
  height: 40px;
  border: none !important;
}
.emojie-option.fine .ui.radio.checkbox .box:before, .emojie-option.fine .ui.radio.checkbox label:before, 
.t4-likert-options tr.likert-emo td:nth-child(3) .emojie-option .ui.radio.checkbox .box:before, .t4-likert-options tr.likert-emo td:nth-child(3) .emojie-option .ui.radio.checkbox label:before
 {
  /*background-image: url(/public/assets/fine.svg);
  border: none !important;
  background-color: transparent !important;
  width: 40px !important;
  height: 40px !important;*/
  background: url(/public/assets/likert-emojies.png)  no-repeat !important;
  background-position: -82px 0px !important;
  width: 40px;
  height: 40px;
  border: none !important;
}
.emojie-option.fine .ui.radio.checkbox input:checked~.box:before, .emojie-option.fine .ui.radio.checkbox input:checked~label:before,
.emojie-option.fine .ui.checkbox input:checked~.box:before, .emojie-option.fine .ui.checkbox input:checked~label:before,
.t4-likert-options tr.likert-emo td:nth-child(3) .emojie-option .ui.radio.checkbox input:checked~.box:before, .t4-likert-options tr.likert-emo td:nth-child(3) .emojie-option .ui.radio.checkbox input:checked~label:before,
.t4-likert-options tr.likert-emo td:nth-child(3) .emojie-option .ui.checkbox input:checked~.box:before, .t4-likert-options tr.likert-emo td:nth-child(3) .emojie-option .ui.checkbox input:checked~label:before
 {
  /*background-image: url(/public/assets/fine-active.svg);
  border: none !important;
  background-color: transparent !important;*/
  background: url(/public/assets/likert-emojies.png)  no-repeat !important;
  background-position: -287px 0px !important;
  width: 40px;
  height: 40px;
  border: none !important;
}

.emojie-option.neutral .ui.radio.checkbox .box:before, .emojie-option.neutral .ui.radio.checkbox label:before,
.t4-likert-options tr.likert-emo td:nth-child(4) .emojie-option .ui.radio.checkbox .box:before, .t4-likert-options tr.likert-emo td:nth-child(4) .emojie-option .ui.radio.checkbox label:before
 {
  /*background-image: url(/public/assets/neutral.svg);
  border: none !important;
  background-color: transparent !important;
  width: 40px !important;
  height: 40px !important;*/
  background: url(/public/assets/likert-emojies.png)  no-repeat !important;
  background-position: -164px 0px !important;
  width: 40px;
  height: 40px;
  border: none !important;
}
.emojie-option.neutral .ui.radio.checkbox input:checked~.box:before, .emojie-option.neutral .ui.radio.checkbox input:checked~label:before,
.emojie-option.neutral .ui.checkbox input:checked~.box:before, .emojie-option.neutral .ui.checkbox input:checked~label:before,
.t4-likert-options tr.likert-emo td:nth-child(4) .emojie-option .ui.radio.checkbox input:checked~.box:before, .t4-likert-options tr.likert-emo td:nth-child(4) .emojie-option .ui.radio.checkbox input:checked~label:before,
.t4-likert-options tr.likert-emo td:nth-child(4) .emojie-option .ui.checkbox input:checked~.box:before, .t4-likert-options tr.likert-emo td:nth-child(4) .emojie-option .ui.checkbox input:checked~label:before
 {
  /*background-image: url(/public/assets/neutral-active.svg);
  border: none !important;
  background-color: transparent !important;*/
  background: url(/public/assets/likert-emojies.png)  no-repeat !important;
  background-position: -369px 0px !important;
  width: 40px;
  height: 40px;
  border: none !important;
}
.emojie-option.good .ui.radio.checkbox .box:before, .emojie-option.good .ui.radio.checkbox label:before,
.t4-likert-options tr.likert-emo td:nth-child(5) .emojie-option .ui.radio.checkbox .box:before, .t4-likert-options tr.likert-emo td:nth-child(5) .emojie-option .ui.radio.checkbox label:before
 {
  /*background-image: url(/public/assets/good.svg);
  border: none !important;
  background-color: transparent !important;
  width: 40px !important;
  height: 40px !important;*/
  background: url(/public/assets/likert-emojies.png)  no-repeat !important;
  background-position: -123px 0px !important;
  width: 40px;
  height: 40px;
  border: none !important;
}
.emojie-option.good .ui.radio.checkbox input:checked~.box:before, .emojie-option.good .ui.radio.checkbox input:checked~label:before,
.emojie-option.good .ui.checkbox input:checked~.box:before, .emojie-option.good .ui.checkbox input:checked~label:before,
.t4-likert-options tr.likert-emo td:nth-child(5) .emojie-option .ui.radio.checkbox input:checked~.box:before, .t4-likert-options tr.likert-emo td:nth-child(5) .emojie-option .ui.radio.checkbox input:checked~label:before,
.t4-likert-options tr.likert-emo td:nth-child(5) .emojie-option .ui.checkbox input:checked~.box:before, .t4-likert-options tr.likert-emo td:nth-child(5) .emojie-option .ui.checkbox input:checked~label:before
 {
  /*background-image: url(/public/assets/good-active.svg);
  border: none !important;
  background-color: transparent !important;*/
  background: url(/public/assets/likert-emojies.png)  no-repeat !important;
  background-position: -328px 0px !important;
  width: 40px;
  height: 40px;
  border: none !important;
}

.emojie-option.loveit .ui.radio.checkbox .box:before, .emojie-option.loveit .ui.radio.checkbox label:before,
.t4-likert-options tr.likert-emo td:nth-child(6) .emojie-option .ui.radio.checkbox .box:before, .t4-likert-options tr.likert-emo td:nth-child(6) .emojie-option .ui.radio.checkbox label:before
 {
  /*background-image: url(/public/assets/loveit.svg);
  border: none !important;
  background-color: transparent !important;
  width: 40px !important;
  height: 40px !important;*/
  background: url(/public/assets/likert-emojies.png)  no-repeat !important;
  background-position: -205px 0px !important;
  width: 40px;
  height: 40px;
  border: none !important;
}
.emojie-option.loveit .ui.radio.checkbox input:checked~.box:before, .emojie-option.loveit .ui.radio.checkbox input:checked~label:before,
.emojie-option.loveit .ui.checkbox input:checked~.box:before, .emojie-option.loveit .ui.checkbox input:checked~label:before,
.t4-likert-options tr.likert-emo td:nth-child(6) .emojie-option .ui.radio.checkbox input:checked~.box:before, .t4-likert-options tr.likert-emo td:nth-child(6) .emojie-option .ui.radio.checkbox input:checked~label:before,
.t4-likert-options tr.likert-emo td:nth-child(6) .emojie-option .ui.checkbox input:checked~.box:before, .t4-likert-options tr.likert-emo td:nth-child(6) .emojie-option .ui.checkbox input:checked~label:before
 {
  /*background-image: url(/public/assets/loveit-active.svg);
  border: none !important;
  background-color: transparent !important;*/
  background: url(/public/assets/likert-emojies.png)  no-repeat !important;
  background-position: -41px 0px !important;
  width: 40px;
  height: 40px;
  border: none !important;
}






.emojie-option .ui.radio.checkbox input:checked~.box:after, .emojie-option .ui.radio.checkbox input:checked~label:after
{
  background-color: transparent !important;
}
.ui.radio.checkbox input:checked~.box:after, .ui.radio.checkbox input:checked~label:after
{
  background-color: #E5E5E5 !important;
}
.ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after {
  color: #E5E5E5 !important;
}
/*MCQ Checkboxes*/
.t4-mcq-checkbox {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
.t4-mcq-checkbox .ui.checkbox {
  display: inline-block;
  text-align: -webkit-match-parent;
  list-style: none;
  padding-bottom: 3px;
  width: 30%;
}
/*Rating Questions*/
.t4-rate ul {
  display: inline-flex;
  padding-inline-start: 0px;
}
.t4-rate li {
  padding: 5px;
}
.t4-rate .ui.radio.checkbox label:before {
  background-image: url(/public/assets/starOut.svg);
  width: 23px;
  height: 22px;
  border: none !important;
  background-color: #fff !important;
}
.t4-rate .ui.radio.checkbox input:checked~label:before, .t4-rate .ui.radio.checkbox.highlight-radio label:before {
  background-image: url(/public/assets/starOn.svg) !important;
  width: 23px;
  height: 22px;
  border: none !important;
}
.t4-rate .ui.radio.checkbox input:checked~label:after {
  display: none !important;
}
.t4-rate .ui.radio.checkbox input:checked~label:before, .t4-rate .ui.checkbox input:checked~label:before {
  background-color: #fff !important;
  border: none !important;
}
/*Input Questions*/
.t4-input .ui.input.t4-input-no, .t4-input .ui.input.t4-input-txt {
  margin-top: 15px;
}
.t4-input .ui.input.t4-input-txt {
  width: 90%;
  margin-top: 1em;
}
/*Radio Questions*/
.t4-likert ul.t4-likert-choices {
  padding-left: 0px !important;
}
/*Dropdowns*/
.t4-mcq .ui.dropdown .menu .selected.item, .ui.dropdown.selected, 
.t4-mainmenu .ui.dropdown .menu .selected.item, .t4-mainmenu .ui.dropdown.selected {
  background: rgba(58,110,181,.4);
}
.t4-mainmenu .ui.fitted.toggle.checkbox {
  padding-left: 0px !important;
}
.t4-mainmenu .ui.toggle.checkbox input:checked~.box:before, .t4-mainmenu .ui.toggle.checkbox input:checked~label:before,
.t4-mainmenu .ui.toggle.checkbox input:focus:checked~.box:before, .t4-mainmenu .ui.toggle.checkbox input:focus:checked~label:before
{
  background-color:#12133E !important
}
.t4-mcq .ui.dropdown .menu>.item:hover {
  background: rgba(58,110,181,.3);
}
/*Likert Questions*/
.t4-likert-options {
  border: none !important;
  text-align: center !important;
}
.t4-likert-options tr td:first-child {
  text-align: left;
}
.ui.striped.table tbody tr:nth-child(1n), .ui.striped.table>tr:nth-child(1n) {
  background-color: #E9E9E9;
}
.ui.striped.table tbody tr:nth-child(2n), .ui.striped.table>tr:nth-child(2n) {
  background-color: #dddddd;
}
.t4-likert-options thead th {
  background: none !important;
  border-top: none !important;
  border-left: none;
}

/*Progress Bar*/
.t4-progress-bar {
  margin-top: 5px;
}
.ui.progress.t4-progress-bar {
  position: relative;
  display: block;
  /*max-width: 50%;*/
  border: none;
  /*margin: 0 auto !important;*/
  box-shadow: none;
  background: #12133E;
  padding: 0;
  border-radius: .28571429rem;
  height: 10px;
}
.t4-progress-bar .bar {
  background-color: #E41E26 !important;
  height: 0.7em !important;
}
.t4-pager .five.wide.column {
  text-align: right;
  font-weight: 700;
}
/*Thank You*/
.t4-survey-finish {
  text-align: center !important;
  margin-left: 15%;
  margin-right: 15%;
}
/*Portal*/
.ui.segment.t4-mainmenu.t4-header-align.t4-nobanner-info {
  margin-top: 0px;
}
.ui.grid.t4-banner-info {
  color: #9190a1 !important;
  margin-top: -155px;
    height: 155px;
}
.t4-banner-info .ui.segment {
  background: transparent !important;
  border-left: 5px solid #E41E26 !important;
  border-radius: 0px !important;
  padding: 5px 10px;
}
.t4-banner-info .eight.wide.column {
  border-left: 5px solid #E41E26;
}
span.t4-banner-numbers {
  font-weight: bolder;
  font-size: 1.2rem;
  margin-right: 15px;
  text-align: right;
  float: right;
}
/*Question Management*/
/*.t4-school-name {
  position: absolute;
    z-index: 1;
    right: 20px;
    top: 111px;
    color: #fff;
    width: 75%;
    text-align: right;
    font-size: 1.8em;
}*/
/*Headers*/
.t4-headers {
  display: inline-flex;
}
.t4-headers.t4-with-ddl {
  width: 100% !important;
  margin-bottom: 10px;
}
.t4-headers h1, .t4-headers h2 {
  margin-top: 0px;
}
.t4-headers .t4-back-btn {
  background-color: transparent !important;
  color: #12133E !important;
}
.t4-headers .t4-back-btn:hover, .t4-headers .t4-back-btn:focus {
  background-color: #e0e1e2 !important;
}
/*******************/
/*Extra Documents*/
td.t4-file {
  word-wrap: break-word;
  word-break: break-all;
}
td.t4-tbl-action a i {
  color: rgba(18,19,62,.6);
}
td.t4-tbl-action a i:hover, td.t4-tbl-action a i:focus {
  color: rgb(18,19,62);
}
.t4-dox i.file {
  color: #12133E !important;
}
.t4-dox i.download.icon {
  color:rgba(18,19,62,.6);
  cursor: pointer;
}
.t4-dox i.download.icon:hover {
  color: #12133E !important;
  cursor: pointer;
}
/*******************/
.ui.floating.dropdown.t4-trans-ddl .menu {
  left: auto !important;
  right: 0 !important;
}
.ui.button.floating.labeled.dropdown.icon.t4-trans-ddl {
  float: right;
  z-index: 998;
}
.t4-trans-ddl.ui.dropdown .menu>.item {
  padding: 0px !important;
}
.t4-trans-ddl.ui.dropdown .menu>.item .button.t4-lang-trans {
  border-radius: 0px !important;
}
.t4-trans-ddl.ui.dropdown .menu>.item button.ui.green.button.t4-lang-trans:hover, 
.t4-trans-ddl.ui.dropdown .menu>.item button.ui.grey.button.t4-lang-trans:hover {
  background: #e0e1e2 !important;
}
.t4-trans-ddl .t4-lang-trans.grey i.close:before, .t4-trans-ddl .t4-lang-trans.grey i.close {
  content: "";
  background-image: url(/public/assets/t4-pending.svg) !important;
  width: 21px !important;
  height: 18px !important;
  vertical-align: sub;
  float: right;
}
.t4-trans-ddl .t4-lang-trans.green i.check:before, .t4-trans-ddl .t4-lang-trans.green i.check {
  content: "";
  background-image: url(/public/assets/t4-check.svg) !important;
  width: 21px !important;
  height: 18px !important;
  vertical-align: sub;
  float: right;
}
.t4-trans-ddl.ui.dropdown .menu>.item button.ui.green.button.t4-lang-trans {
  /*background: #96E273 !important;*/
  background: #fff !important;
}
.t4-trans-ddl.ui.dropdown .menu>.item button.ui.grey.button.t4-lang-trans {
  /*background-color: #E27379 !important;*/
  background: #fff !important;
}
i.flag.t4-lang-stat {
  float: left !important;
}
span.t4-lang-name {
  color: #12133E !important;
  float: left;
  margin-left: 15px;
}
/*Modal*/
.t4-actions {
  padding-top: 20px;
}
.t4-actions button.ui.blue.button.t4-btn-active {
  margin-left: 10px;
}
.ui.modal>.actions {
  background: #fff !important;
  padding: 0.5rem 0.5rem !important;
  border-top: none !important;
  text-align: right;
}
.t4-modal-txt p {
  margin-top: 6px;
}
.t4-popup .ui.disabled.input input {
  border: 1px solid rgba(34,36,38,.15) !important;
}
.t4-modal-btns button.ui.icon.positive.button.t4-btn {
  margin-left: 10px;
}
/*Button Pages*/
.ui.segment.t4-mainmenu.t4-btn-pages {
  padding: 0px !important;
  margin-top: 0px !important;
}
.t4-btn-page-content {
  padding: 1em 2em !important;
}
/*Buttons Ribbon*/
/*.t4-sticky-btns {
  position: sticky;
  top: -1px;
  padding: 0.3em 1.5em;
  z-index: 101;
  background-color: #fff !important;
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
}*/
.t4-sticky-btns {
  position: sticky;
  top: -1px;
  padding: 0.3em 1.5em;
  z-index: 101;
  background-color: #fff !important;
  display: table !important;
    /*border-bottom: 1px solid #12133E;*/
    width: 100% !important;
    box-shadow: 0 0px 0px 0px rgb(153 153 153 / 42%), 0 12px 14px -11px rgb(0 0 0 / 9%), 0 5px 1px -5px rgb(153 153 153 / 20%) !important;
}
/*School Details*/
.ui.form.t4-editschool input[type=checkbox], .ui.form.t4-add-edit input[type=checkbox] {
  vertical-align: text-bottom;
  margin-right: 6px;
}
.ui.form.t4-editschool .field .input, .ui.form.t4-editschool .field div.dropdown {
  width: 50% !important;
}
.t4-edit-data {
  margin-top: 20px;
}
/*Staff Pages*/
.t4-header-align .t4-header-collection {
  /*margin-bottom: 45px;*/
  margin-top: 10px;
}
.t4-header-align h1.ui.left.aligned.header.t4-header-left, .t4-headers h1.ui.left.aligned.header.t4-header-left {
  margin-bottom: 0px !important;
  float: left;
  margin-top: 0px !important;
}
.t4-header-align h4.ui.right.aligned.header.t4-header-right {
  margin-top: 10px !important;
  float: right;
}
/*Tables*/
span.t4-sortby {
  font-size: 1rem !important;
  color: #12133E !important;
  font-weight: 700;
  line-height: 1em;
}
.dropdown.t4-sorted .divider.text {
  color: #4054b2 !important;
}
.t4-filter {
  background-color: transparent !important;
  float: right;
  padding: 0px 1px !important;
  text-align: right;
  position: absolute;
  margin-right: 0px !important;
  margin-left: 10px !important;
  font-size: 0.8em !important;
}
.ui.section.divider.t4-divider {
  margin: 5px;
}
.t4-filter-ddl {
  background-color: transparent !important;
  float: right;
  padding: 6px 1px !important;
  text-align: right;
  margin-right: 0px !important;
  font-size: 0.8em !important;
}
.t4-multi-filter-ddl {
  background-color: transparent !important;
  float: right;
  padding: 6px 1px !important;
  text-align: right;
  margin-right: 0px !important;
  font-size: 0.8em !important;
}
.t4-filter-ddl.ui.floating.dropdown .menu {
  left: auto !important;
  right: 0 !important;
}
.ui.dropdown.t4-multi-filter-ddl .menu>.item:first-child:hover {
  background: transparent !important;
}
.t4-multi-filter-ddl i.filter.icon {
  color: rgba(0,0,0,.3);
}
.t4-multi-filter-ddl.selected i.filter.icon {
  color: #4054b2 !important;
}
.t4-multi-filter-src {
  margin: 0px !important;
}
.t4-multi-filter-ddl.ui.floating.dropdown .menu {
  left: auto !important;
  right: 0 !important;
}
/*Survey Settings*/
.t4-settings-txtq textarea {
  margin: 11px 0px 3px 0px;
  width: 95%;
  border: 1px solid rgba(34,36,38,.15);
  color: rgba(0,0,0,.87) !important;
  padding: 13px;
  border-radius: .28571429rem
}
.t4-settings-txtq .error.field .ui.basic.red.label {
  margin-left: 36px;
}
.t4-spacing {
  margin-top: 20px !important;
}
/*Add Questions*/
.t4-add-q .ui.input {
  width: 95% !important;
}
.t4-add-mcq .item {
  margin-bottom: 5px !important;
}
.t4-add-mcq .input {
  width: 50% !important;
}
.t4-add-mcq .item i.trash.alternate.outline.icon {
  font-size: 20px;
  vertical-align: baseline;
  margin-left: 5px !important;
  display: inline;
  color: #12133e !important;
  cursor: pointer;
}
/*Edit Question*/
.t4-settings-edit-quest .ui.button.floating.labeled.dropdown.icon.t4-trans-ddl {
  margin-top: 28px;
  margin-right: 20px;
  margin-bottom: 15px;
}
.t4-settings-mcqq .ui.list.t4-add-mcq {
  margin-top: 15px !important;
}
.ui.message.t4-edit-trans-settings {
  background-color: #fffaf3;
  color: #573a08;
  -webkit-box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent;
  box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent;
  width: fit-content;
}
/*Import*/
.t4-import-step {
  /*width: 330px;*/
  font-family: 'Helvetica';
  font-size: 14px;
  /*border: 1px solid #CCC;*/
}

.t4-StepProgress {
  position: relative;
  padding-left: 45px;
  list-style: none;
}
.t4-StepProgress::before {
  display: inline-block;
  content: '';
  position: absolute;
  top: 0;
  left: 15px;
  width: 10px;
  height: 100%;
  border-left: 2px solid #CCC;
}
.t4-StepProgress-item {
  position: relative;
  counter-increment: list;
}
.t4-StepProgress-item:not(:last-child) {
  padding-bottom: 20px;
}
.t4-StepProgress-item::before {
  display: inline-block;
  content: '';
  position: absolute;
  left: -30px;
  height: 100%;
  width: 10px;
}
.t4-StepProgress-item::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: -39px;
  width: 20px;
  height: 20px;
  border: 2px solid #CCC;
  border-radius: 50%;
  background-color: #FFF;
}
.t4-StepProgress-item.is-done::before {
  border-left: 2px solid #E41E26 ;
}
.t4-StepProgress-item.is-done::after {
  content: "✔";
  font-size: 10px;
  color: #FFF;
  text-align: center;
  border: 2px solid #E41E26;
  background-color: #E41E26;
}
.t4-StepProgress-item.current::before {
  border-left: 2px solid #E41E26;
}
.StepProgress-item.current::after {
  content: counter(list);
  padding-top: 1px;
  width: 19px;
  height: 18px;
  top: -4px;
  left: -40px;
  font-size: 14px;
  text-align: center;
  color: #E41E26;
  border: 2px solid #E41E26;
  background-color: white;
}
p.t4-substep {
  padding: 6px 0px 6px 10px !important;
  color: #6F686D;
  font-style: italic;
  font-size: 13px !important;
}
.t4-StepProgress strong {
  display: block;
}
div#divValidationMessages {
  width: fit-content;
  margin-top: 8px;
  color: #E41E26 !important;
}
.t4-error-msg i {
  display: block !important;
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 auto !important;
    flex: 0 0 auto !important;
    width: auto !important;
    line-height: 1 !important;
    vertical-align: middle !important;
    font-size: 2em !important;
    opacity: .8 !important;
    color: #E41E26 !important;
}
.t4-error-msg i.icon.t4-errors {
  width: initial !important;
  font-size: 1em !important;
  line-height: initial !important;
  display: inline-block !important;
}
.ui.icon.negative.message.t4-error-msg {
  box-shadow: none;
  border-left: 5px solid #E41E26;
  color: #12133E!important;
    background-color: #FEEEEF !important;
}
.t4-error-msg.small i {
  display: block !important;
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 auto !important;
    flex: 0 0 auto !important;
    width: auto !important;
    line-height: 1 !important;
    vertical-align: middle !important;
    font-size: 1.2em !important;
    opacity: .8 !important;
    color: #E41E26 !important;
}
.ui.negative.message.t4-error-msg .header {
  color: #12133E !important;
}

.App .t4-steps li.t4-listnum {
  float: left;
  width: 100%;
  text-align: left;
  margin-bottom: 8px;
}
.App .t4-steps li.t4-listbtn {
  float: left;
  width: 100%;
  text-align: left;
  margin-bottom: 15px;
}
.App .t4-steps .label {
  margin-right: 15px !important;
}
.App .t4-steps li p {
  display: inline-flex;
}
.App .t4-steps .t4-import-btn, .App button.ui.basic.button.exp-btn, .button.t4-import-btn, .button.dl-btn.t4-import-btn {
  margin-top: 6px !important;
}
.t4-red-color {
  background: #E41E26 !important;
  color: #fff !important;
}
.t4-upload {
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  outline: 0;
  -webkit-appearance: none;
  line-height: 1.21428571em;
  padding: 0.2em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34,36,38,.15);
  color: rgba(0,0,0,.87);
  border-radius: .28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color .1s ease,border-color .1s ease;
  width: 50%;
}
.t4-upload input#csvFileInput {
  width: 100%;
}
.t4-btn-push {
  margin-top: 6px !important;
}
.t4-upload input::file-selector-button {
  font-weight: bold;
  border: none;
  cursor:pointer;
  margin-right: 10px;
  background: rgb(19,20,62);
    background: linear-gradient(135deg, rgba(19,20,62,1) 0%, rgba(58,88,161,1) 100%) !important;
    color: #fff !important;
    border-radius: .28571429rem;
    padding: 10px 40px !important;
}
.t4-mainmenu .t4-item {
  margin-bottom: 15px;
}
.t4-topnav-menu.menu {
  margin-bottom: 0px !important;
  border: none !important;
  box-shadow: none !important;
}
.t4-mng .right.menu .item {
  padding-bottom: 0px !important;
}
.t4-logout-dd .menu.transition.visible {
  top: 20px;
}
.t4-topnav-menu.menu .item:before {
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
}
.t4-mng-menu {
  float: right;
  color: #12133e !important;
}
.t4-mng-menu .visible.t4-menu-dd {
  z-index: 999 !important;
}
.App .ui.menu .t4-mng-menu.ui.dropdown .visible.t4-menu-dd.menu>.item {
  padding: 0.7em 1em 0.7em 3.142857em !important;
  font-weight: initial !important;
}
.t4-mng-menu .t4-menu-dd i.icon:before {
  font-size: 0.8em !important;
    margin-right: 2px;
}

.t4-mng-menu .t4-menu-dd .header {
  font-weight: 700 !important;
  text-align: left !important;
  font-size: 0.9em !important;
  padding: 0 0.8rem !important;
}
.t4-mng-btns {
  /*float: left;*/
  margin-bottom: 20px !important;
  display: contents;
}
.t4-mng-btns button.ui.button {
  margin-top: 10px !important;
  margin-bottom: -5px;
}
.t4-form-btns {
  margin-bottom: 20px !important;
  display: contents;
  padding-left: 0px;
}
.t4-mng-btns-cont {
  min-height: 40px;
}
.t4-mng-menu i {
  font-size: 25px !important;
}
.t4-mng-menu .menu>.item a {
  color: #12133e !important;
}
.ui.menu .ui.dropdown.t4-mng-menu .menu>.item:hover {
  background: rgba(58,110,181,.3) !important;
  color: #12133e !important;
  z-index: 13;
}
.ui.menu .ui.dropdown.t4-mng-menu .menu>.item:active, .ui.menu .ui.dropdown.t4-mng-menu .menu>.item:focus {
  background: rgba(58,110,181,.4) !important;
  color: #12133e !important;
  z-index: 13;
}
.t4-logout-dd button.ui.button {
  background: transparent !important;
}
.t4-nav-menu {
  width: 50%!important;
  float: right !important;
  border: none !important;
  box-shadow: none !important;
}
.top-nav-lang-ddl .dropdown.language-btn-dd {
  padding: .78571429em .78571429em .78571429em !important;
}
.top-nav-lang-ddl .language-btn-dd i.flag.langflag {
  margin-left: 5px;
}
.t4-trans-ddl .t4-lang-trans {
  width: 100% !important;
}
button.ui.green.button.t4-lang-trans {
  background: rgb(19,20,62) !important;
  background: linear-gradient(90deg, rgba(19,20,62,1) 0%, rgba(58,88,161,1) 100%) !important;
}
button.ui.green.button.t4-lang-trans:hover, button.ui.green.button.t4-lang-trans:focus {
  background: rgb(19,20,62) !important;
  background: linear-gradient(90deg, rgba(58,88,161,1) 0%, rgba(19,20,62,1) 100%) !important;
}
button.ui.grey.button.t4-lang-trans {
  background-color: #e5e5e5;
}
.ui.input.t4-add-edit-questions {
  width: 100%;
}
textarea.t4-add-edit-questions {
  display: grid;
  margin: 10px 0;
  width: 100%;
  border: 1px solid rgba(34,36,38,.15);
    margin-right: 0px !important;
    padding: 10px !important;
    margin-bottom: 30px;
    border-radius: .28571429rem;
}
.row.t4-add-edit-answers {
  padding: 5px 0px !important;
}
.t4-add-edit-answers .ui.input {
  width: 100%;
}
.t4-mng .likert-main-header {
  display: initial !important;
}
.t4-school-info-section, form.ui.form.t4-add-edit {
  margin-top: 25px;
}
.t4-school-details div {
  padding-bottom: 8px;
}
.t4-school-info-section h3.t4-school-info-sub {
  border-bottom: 2px solid #eaeaea;
  padding-bottom: 5px;
}
.t4-add-new-school h3.t4-school-info-sub {
  border-bottom: 2px solid #eaeaea;
  padding-bottom: 5px;
  margin-left: 1rem;
    padding-left: 0px !important;
    width: 97%;
    margin-top: 20px;
    margin-bottom: 8px;
}
.t4-detail-row {
  padding: 4px 0 15px 20px !important;
  /*border-bottom: 1px solid #eaeaea;*/
  color: #888B9F;
}

span.t4-detail-main {
  font-weight: 700;
  display: inline-flex;
  vertical-align: middle;
  margin-right: 10px;
  color: #12133E !important;
}
/*span.t4-detail-main:before {
  content: "";
  background-image: url(/public/assets/t4-bot-bracket_small.svg) !important;
  width: 10px;
  height: 10px;
  display: block;
  margin-top: 12px;
  
}
span.t4-detail-main:after {
  content: "";
  background-image: url(/public/assets/t4-top-bracket_small.svg) !important;
  width: 10px;
  height: 10px;
  display: block;
}*/
/*Main Mneu*/
.t4-mainmenu-cards {
  border-left: 8px solid #12133E !important;
  border-radius: 0.5rem !important;
  color: #12133E !important;
  box-shadow: 0 0px 0px 0px rgb(153 153 153 / 42%), 0 1px 9px 0px rgb(0 0 0 / 12%), 0 5px 1px -4px rgb(153 153 153 / 20%) !important
}
.t4-mainmenu-cards.t4-content-cards {
  border-left: none !important;
  border-radius: 0.5rem !important;
  color: #12133E !important;
  box-shadow: 0 0px 0px 0px rgb(153 153 153 / 42%), 0 1px 9px 0px rgb(0 0 0 / 12%), 0 5px 1px -4px rgb(153 153 153 / 20%) !important
}
a.ui.card.t4-mainmenu-cards {
  height: 50px;
}
a.ui.card.t4-mainmenu-cards.t4-content-cards {
  height: auto;
  min-height: 100px;
}
.t4-content-cards .header, .ui.card.t4-content-cards>:first-child, .ui.cards>.card.t4-content-cards>:first-child {
  padding: 1em 1em;
  font-weight: 700;
  font-size: 1.28571429em;
  margin-top: -.21425em;
  line-height: 1.28571429em;
  background-color: #12133E !important;
  color: #FFF !important;
  border-radius: .5rem .5rem 0 0!important;
}
i.t4-card-icn.icon {
  float: right;
}
.t4-mainmenu-cards img.ui.mini.right.floated.image {
  width: 55px;
}
img.ui.mini.right.floated.image.report-card-image {
  width: 45px;
}
.t4-mainmenu-cards .meta {
  margin-top: 3px;
  font-size: 0.9em !important;
}
/*Dashboard*/
.t4-tbl-dash thead tr td {
  background: #12133E !important;
  color: #fff;
}
.ui.table.t4-tbl-dash.t4-tbl-dash thead tr td, .ui.table.t4-tbl-dash.t4-tbl-dash tbody tr td {
  text-align: center !important;
}
.ui.table.t4-tbl-dash.t4-tbl-dash thead tr td:first-child, .ui.table.t4-tbl-dash.t4-tbl-dash tbody tr td:first-child {
  text-align: left !important;
}
.t4-tbl .ui.fitted.checkbox {
  padding-left: 20px;
}
.t4-tbl.t4-tbl-long .ui.fitted.checkbox {
  padding-left: initial !important;
}
.t4-action-btns {
  padding-bottom: 15px;
  float: left;
}
.t4-actions .button {
  margin-right: 15px;
}
.t4-mainmenu .ui.icon.input {
  float: right !important;
  /*margin-bottom: 18px;*/
  margin-bottom: 10px;
  margin-top: 9px;
}
.t4-filter-ddl .ui.left.icon.input.search {
  float: initial !important;
}
.t4-multi-filter-ddl .ui.left.icon.input.search {
  float: initial !important;
}
.t4-mainmenu {
  padding: 1em 2em !important;
}
.ui.orange.basic.label.t4-status-incomp, .ui.green.basic.label.t4-status-incomp,
.ui.red.basic.label.t4-status-incomp, .ui.yellow.basic.label.t4-status-incomp,
.ui.blue.basic.label.t4-status-incomp
 {
  /*background: #ffedde !important;
  border-radius: 15px;*/
  vertical-align: middle;
  padding: 0px;
  border:0px !important;
  background-color: transparent !important;
  font-size: 10px;
  top: 34px !important;
  position: absolute;
}
.t4-status-incomp i.exclamation.triangle.icon {
  margin-right: 3px !important;
}
span.t4-warn {
  color: #E41E26 !important;
  font-size: 10px;
  top: 29px !important;
  position: absolute;
}
.t4-home-settings {
  display: flex;
  height: 50px !important;
  margin: 1px 0px 6px 0px;
}
.t4-mng-icon-btns {
  right: 30px !important;
  position: absolute;
}
a.ui.basic.button.t4-manage-btn {
  padding: 9px 16px;
}
/*.t4-settings-btn {
  position: absolute;
  right: 2.5em !important;
}*/
.t4-settings-btn i {
  color: #12133E !important;
}
.ui.segment.t4-dashboard.t4-mainmenu {
  padding-top: 0px !important;
}
.t4-dashboard-cards {
  background-color: #12133E !important;
  border-left: 8px solid #E41E26 !important;
  border-radius: 1rem !important;
  color: #fff !important;
}
.t4-dashboard-cards .content .description {
  color: #9190a1 !important;
  font-weight: 700;
}
.t4-dashboard-cards .content .header {
  color: #fff !important;
  padding: 20px 0px 5px 0px;
  font-size: 2em !important;
}
/*Survey Settings*/
.t4-survey-settings-section {
  margin-bottom: 30px !important;
  padding-bottom: 30px;
  border-bottom: 1px solid #eaeaea;
}
.t4-lang-multi-settings i.flag {
  margin-right: 1em !important;
}
.t4-survey-settings-section .ui.multiple.selection.dropdown.t4-lang-multi-settings
 {
  vertical-align: middle !important;
}
.t4-survey-settings-section .ui.selection.dropdown.t4-lang-settings {
  vertical-align: middle !important;
  width: max-content;
}
.t4-lang-setting-option {
  margin-bottom: 10px;
}
/*Messages*/
.t4-likert-options .ui.red.message {
  width: 100% !important;
  background-color: white !important;
  border: none !important;
  box-shadow: none;
  text-align: left !important;
}
.ui.segment.t4-likert.required table .likert-emo, .ui.segment.t4-likert.required table.t4-likert-head {
  background-color: #fff6f6!important;
}
.ui.segment.t4-likert.required .ui.red.message {
}
.t4-error, .ui.red.message {
  background-color: #fff6f6!important;
    box-shadow: inset 0 0 0 1px #e0b4b4, 0 0 0 0 transparent !important;
    color: #9f3a38 !important;
}
.ui.segment.t4-mcq.required, .ui.segment.t4-likert.required, .ui.segment.t4-input.required {
  box-shadow: inset 0 0 0 1px #e0b4b4, 0 0 0 0 transparent !important;
  color: #9f3a38 !important;
  background-color: #fff6f6!important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.ui.segment.t4-input.required {
  padding-bottom: 10px !important;
}
.t4-mcq.required .ui.red.message {
  color: #9f3a38 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.ui.table.t4-likert-head td.six.wide, .ui.table.t4-likert-head .likert-emo th.six.wide {
  width: 37.5% !important;
}
.ui.table.t4-likert-head td, .ui.table.t4-likert-head .likert-emo td {
  padding: initial 0.4px !important;
}
.t4-mcq-radio .ui.selection.upward.dropdown {
  width: fit-content !important;
}
/*Description*/
.t4-desc {
  color: #6F686D !important;
  font-size: smaller;
  padding-top: 6px;
}
/*Subquestion*/
.t4-subquestion {
  padding-top: 6px;
  color: #6F686D;
  font-style: italic;
  font-size: 13px !important;
}
/*Scoring Matrix*/
.t4-scoring-wrapper h2 {
  color: #3A73B9;
  font-size: 1.4rem;
}
ul.t4-scoring-wrapper li {
  margin-bottom: 25px;
}
ul.t4-scoring-wrapper {
  padding-left: 0px !important;
}
.t4-foundation table.ui.table {
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
}
ul.t4-scoring-wrapper thead td {
  /*border-bottom: 4px solid #E41E26;*/
  font-weight: 700;
  color: #12133E !important;
}
.t4-foundation-header {
  height: 35px !important;
}
.t4-pillars .t4-foundation h3 {
  float: left;
}
.t4-pillars .t4-foundation button.ui.button {
  float: right;
  margin-bottom: 10px !important;
}
ul.t4-pillars {
  padding-left: 15px;
}
.t4-pillars input[type="text"] {
  width: 50px;
  text-align: center;
}
.ui.segment.t4-mainmenu.t4-edit-score ul.t4-pillars {
  padding-left: 0px !important;
}
li.t4-foundation {
  margin-bottom: 30px;
}
.t4-foundation .ui.table.t4-tbl thead td:first-child, .t4-foundation .ui.table.t4-tbl tr td:first-child  {
  text-align: left !important;
}
.t4-foundation .ui.table.t4-tbl tr td, .t4-foundation .ui.table.t4-tbl thead td {
  text-align: center !important;
}
.ui.table.t4-tbl tr td, .ui.table.t4-tbl thead td {
  text-align: left !important;
}
.ui.table.t4-tbl thead {
  background-color: #f2f2f2;
}
.ui.table.t4-tbl thead td:first-child {
  border-left: 4px solid #E41E26 !important;
}
/*Flags*/
i.flag:before {
  background-position: initial !important;
}
i.flag.uk:before {
  background-image: url(/public/assets/flags/gb.svg) !important;
  width: 44px !important;
  height: 17px !important;
}
i.flag.uae:before {
  background-image: url(/public/assets/flags/ae.svg) !important;
  width: 44px !important;
  height: 17px !important;
}
i.flag.es:before {
  background-image: url(/public/assets/flags/es.svg) !important;
  width: 44px !important;
  height: 17px !important;
}
i.flag.se:before {
  background-image: url(/public/assets/flags/se.svg) !important;
  width: 44px !important;
  height: 17px !important;
}
i.flag.pt:before {
  background-image: url(/public/assets/flags/pt.svg) !important;
  width: 44px !important;
  height: 17px !important;
}
i.flag.th:before {
  background-image: url(/public/assets/flags/th.svg) !important;
  width: 44px !important;
  height: 17px !important;
}
/*Loader*/
table.ui.table.t4-tbl.t4-tbl-dash .ui.segment.t4-loader {
  position: inherit;
  width: 100% !important;
}
.segment.t4-loader {
  margin-top: 0px !important;
  min-height: 100px;
}
.segment.t4-loader .ui.active.transition.visible.inverted.dimmer {
  display: flex !important;
  z-index: 1;
}
.ui.text.loaderr.t4-loading  {
  width: auto!important;
  height: auto!important;
  text-align: center;
  font-style: normal;
}

.ui.dimmer .loaderr.t4-loading  {
  display: block;
}
.ui.text.loaderr.t4-loading  {
  min-width: 2.28571429rem;
  padding-top: 3.07142857rem;
}

.ui.text.loaderr.t4-loading  {
  width: auto!important;
  height: auto!important;
  text-align: center;
  font-style: normal;
}
.ui.loaderr.t4-loading {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  text-align: center;
  z-index: 1000;
}
.ui.loaderr.t4-loading:before, .ui.loaderr.t4-loading:after {
  width: 2.28571429rem;
  height: 2.28571429rem;
  margin: 0 0 0 -1.14285714rem;
}
.ui.loaderr.t4-loading:before {
  background-image: url(/public/assets/t4-Loading.gif) !important;
  background-repeat: no-repeat;
  background-color: transparent !important;
  border: none !important;
  border-radius: initial !important;
  position: absolute;
    content: '';
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
}
.ui.loaderr.t4-loading:after {
  display: none !important;
}
/*Invitations*/
h3.t4-error-msg {
  color: #7F7F7F;
  text-align: center;
  width: 50%;
    margin: 20px auto;
}
/*Reports Page*/
.t4-reports-status, .t4-reports-cards .header {
  margin-left: 12px;
}
a.t4-reports-links {
  margin: 10px 8px 0px 12px;
}
button.ui.button.t4-reports-links {
  margin: 10px 12px 0px 8px;
}
button.ui.button.t4-reports-links {
  padding: 0px;
  background-color: #fff;
}
.t4-reports-cards .extra.content {
  display: flex;
}
.t4-reports-cards .description {
  margin-top: 12px !important;
}
.t4-waves.t4-reports-cards {
  width: inherit !important;
  border-left: 8px solid #12133E !important;
  /*border: 1px solid #12133E !important;*/
  border-radius: 0.5rem !important;
  color: #12133E !important;
  box-shadow: 0 0px 0px 0px rgb(153 153 153 / 42%), 0 1px 9px 0px rgb(0 0 0 / 12%), 0 5px 1px -4px rgb(153 153 153 / 20%) !important;
  margin-right: 69px !important;
}
.t4-reports-cards {
  /*width: 48% !important;
  width: 31% !important;*/
  border-left: 8px solid #12133E !important;
  /*border: 1px solid #12133E !important;*/
  border-radius: 0.5rem !important;
  color: #12133E !important;
  box-shadow: 0 0px 0px 0px rgb(153 153 153 / 42%), 0 1px 9px 0px rgb(0 0 0 / 12%), 0 5px 1px -4px rgb(153 153 153 / 20%) !important;
  margin: 0.5em 0.5em !important;
}
.t4-reports-cards.t4-content-cards {
  border-left: none !important;
  border-radius: 0.5rem !important;
  color: #12133E !important;
  box-shadow: 0 0px 0px 0px rgb(153 153 153 / 42%), 0 1px 9px 0px rgb(0 0 0 / 12%), 0 5px 1px -4px rgb(153 153 153 / 20%) !important;
}
.t4-report-type {
  border: 1.5px solid #12133E;
  border-radius: 0.5rem !important;
  text-align: center !important;
  width: 50%;
    margin: 10px 18px;
    /*padding: 10px;*/
}
.t4-waves-counter {
  font-weight: 700;
}
div#divSurveyCounters {
  margin: 19px 0px 0px 10px;
  padding-left: 10px;
  float: right;
  border-left: 5px solid #4054b2 !important;
}
div#divSurveyCounters .ui.horizontal.label {
  background-color: transparent !important;
  color: #4054b2 !important;
  font-weight: 700;
  margin-right: 0px;
}
.t4-waves .t4-report-type, .t4-chains .t4-report-type {
  width:100% !important;
  display: grid;
  margin: auto !important;
}
.t4-waves .t4-report-type:hover, .t4-chains .t4-report-type:hover {
  background-color: #12133E;
  color: #ffff !important;
  border-radius: 0.5rem !important;
  text-align: center !important;
  width: 50%;
    margin: 10px 18px;
    /*padding: 10px;*/
}
/*.t4-waves .t4-report-type i.icon.t4-vis-report {
  margin: 0 auto !important;
  content: "";
  background-image: url(/public/assets/t4-reports.svg) !important;
    width: 65px !important;
    font-size: 82px !important;
}
.t4-waves .t4-report-type i.icon.t4-vis-report:hover {
  background-image: url(/public/assets/t4-reports-msg.svg) !important;
}*/
i.large.icon.t4-vis-report {
  font-size: 1em;
}
.t4-waves .t4-report-type.t4-vis {
  margin: 0 auto !important;
  content: "";
  background-image: url(/public/assets/t4-reports.svg) !important;
  background-repeat: no-repeat;
    /*width: 65px !important;*/
    font-size: 82px !important;
    /*background-size: 90px;*/
    background-size: 50%;
    background-position: center 10%;
}
.t4-waves .t4-report-type.t4-vis:hover {
  background-image: url(/public/assets/t4-reports-invt.svg) !important;
}
.t4-chains .t4-report-type.t4-vis {
  margin: 0 auto !important;
  content: "";
  background-image: url(/public/assets/t4-reports.svg) !important;
  background-repeat: no-repeat;
    /*width: 65px !important;*/
    font-size: 82px !important;
    /*background-size: 90px;*/
    background-size: 55%;
    background-position: center 10%;
}
.t4-chains .t4-report-type.t4-vis:hover {
  background-image: url(/public/assets/t4-reports-invt.svg) !important;
}
.t4-report-type.t4-data i.download.icon {
  margin-right: .50em !important;
}
.t4-chains .t4-report-type.t4-data {
  margin: 0 auto !important;
  content: "";
  background-image: url(/public/assets/t4-details.svg) !important;
  background-repeat: no-repeat;
    /*width: 65px !important;*/
    font-size: 82px !important;
    /*background-size: 90px;*/
    background-size: 44%;
    background-position: center 10%;
}
.t4-chains .t4-report-type.t4-data:hover {
  background-image: url(/public/assets/t4-details-invt.svg) !important;
}
.t4-report-type .ui.label {
  background-color: initial !important;
  padding-top: 10px !important;
  /*font-size: 15px !important;*/
  color: #9190a1 !important;
}
/*.t4-reports-cards .t4-toggle-lbl {
  width: 50%;
  text-align: right;
}
.t4-reports-status {
  width: 50%;
}*/
/*.t4-reports-status .ui.orange.basic.label.t4-status-incomp {
  vertical-align: initial !important;
  padding: initial !important;
  border: initial !important;
  background-color: transparent !important;
  font-size: initial !important;
  top: initial !important;
  position: initial !important;
  background: none #fff !important;
  color: #f2711c !important;
  border-color: #f2711c !important;
  padding: .5833em .833em;
  border: 1px solid #f2711c !important;
  position: initial;
  font-size: .85714286rem !important;
}*/
.ui.segment.t4-mainmenu.t4-reports {
  min-height: 700px;
  margin-top: 0px;
  height: 100vh !important;
}
button.ui.basic.right.floated.button.t4-btn.t4-create.t4-report-dl {
  padding: 12px 25px !important;
  margin-left: 10px;
}
button.ui.basic.right.floated.button.t4-btn.t4-create.t4-report-dl:hover {
  background: rgb(19,20,62);
  background: linear-gradient(135deg, rgba(19,20,62,1) 0%, rgba(58,88,161,1) 100%) !important;
  color: #fff !important;
  border-radius: .28571429rem;
}
button.ui.basic.right.floated.button.t4-btn.t4-create.t4-report-dl i.download.icon {
  margin-left: 10px !important;
}
.t4-reports .ui.fitted.toggle.checkbox {
  margin-left: 11px;
  vertical-align: middle;
}
.t4-report-status {
  float: left;
  margin-bottom: 10px;
}
.t4-reports .t4-action-ddl {
  margin-bottom: 10px;
}
.t4-reports .t4-action-ddl .ui.label.t4-select-ddl {
  background: transparent !important;
  font-size: 1rem !important;
  color: #12133E !important;
}
.t4-toggle-lbl {
  background: transparent !important;
  font-size: 1rem !important;
  color: #12133E !important;
  padding-left: 0px !important;
}
.t4-status-lbl {
  background: transparent !important;
  font-size: 1rem !important;
  color: #12133E !important;
  margin-right: 0.5em !important;
  padding: 0em 0.833em 0.2em 0em !important;
}
.t4-toggle-lbl .toggle.checkbox {
  margin-left: 0px !important;
}
.ui.segment.t4-reports-msg {
  margin-top: 4rem;
}
/*Add Edite Survey Questions*/
.ui.dropdown.button.t4-add-btn {
  background: none;
  padding: 0px !important;
  color: #12133E !important;
}
.ui.dropdown.button.t4-add-btn:hover {
  background: none !important;
}
.t4-actions.t4-save-btns .blue.button.t4-btn-active {
  margin-left: 10px;
}
/*Add Edit School Profile*/
.t4-form-bot-btns div#divValidationMessages {
  float: right;
  width: 100%;
  margin-bottom: 10px;
}
.t4-form-bot-btns, .t4-actions.t4-save-btns {
  margin-top: 20px;
  display: flow-root;
}
.t4-form-bot-btns .button.t4-btn-non {
  margin-right: 10px !important;
}
/*Mobile*/
/*@media only screen and (max-width: 765px) {
  .flip-phone-viewer .ui.table:not(.unstackable) tbody, .ui.table:not(.unstackable) tr, .flip-phone-viewer .ui.table:not(.unstackable) tr>td, .ui.table:not(.unstackable) tr>th {
      width: auto!important;
      display: table-cell !important;
  }
  }*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .t4-action-btn.t4-surv {
    margin: 20px 0px 50px 12px !important;
  }
  .ui.container.t4-survey-wrapper.t4-mng {
    width: 1127px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .ui.message.t4-edit-trans-settings {
    width: 100% !important;
    display: flex !important;
}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .t4-action-btn.t4-surv {
    margin: 20px 0px 50px 12px !important;
  }
  .ui.grid.t4-banner-info {
    color: #9190a1 !important;
    margin-top: -123px !important;
    height: 130px !important;
}
.ui.message.t4-edit-trans-settings {
  width: 100% !important;
  display: flex !important;
}
}
@media only screen and (min-width:667px) and (max-width: 767px) {
  .ui.message.t4-edit-trans-settings {
    width: 100% !important;
    display: flex !important;
}
  .t4-action-btn.t4-surv {
    margin: 20px 0px 50px 12px !important;
  }
  .t4-popup.t4-reset-popup {
    width: 95% !important;
}
.t4-home {
    position: relative !important;
    width: calc(100% - 1px);
    margin-bottom: 28px;
    background-color: #fff;
    /*-webkit-box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    -moz-box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);*/
    min-width: 320px;
    min-height: 338px;
    overflow: hidden;
    min-height: 500px;
    left: initial !important;
    top: initial !important;
}
.ui.grid.t4-banner-info {
  color: #9190a1 !important;
  margin-top: initial !important;
  height: auto !important;
}
/*.t4-school-name {
  top: 60px !important;
}*/
.flip-phone-viewer .ui.table:not(.unstackable) tbody, .ui.table:not(.unstackable) tr, .flip-phone-viewer .ui.table:not(.unstackable) tr>td, .ui.table:not(.unstackable) tr>th {
    display: table-cell !important;
}
.ui.table td.six.wide, .ui.table th.six.wide {
  width: 37.5% !important;
}
.ui.table.t4-likert-head td, .ui.table.t4-likert-head .likert-emo td,  .likert-tbl .likert-emo td {
  padding: 0.4px !important;
}
.quest-type2 .ui.table:not(.unstackable) tbody, .quest-type2 .ui.table:not(.unstackable) tr, .quest-type2 .ui.table:not(.unstackable) tr>td, .quest-type2 .ui.table:not(.unstackable) tr>th {
  display: table-cell !important;
}
.t4-likert-head .emojie-option .ui.radio.checkbox .box, .t4-likert-head .emojie-option .ui.radio.checkbox label {
  padding-left: 4px !important;
}
}
@media only screen and (max-width:667px){
  .t4-home {
    position: relative !important;
    width: calc(100% - 1px);
    margin-bottom: 28px;
    background-color: #fff;
    /*-webkit-box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    -moz-box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);*/
    min-width: 320px;
    min-height: 338px;
    overflow: hidden;
    min-height: 500px;
    left: initial !important;
    top: initial !important;
}
  /*.t4-school-name {
    top: 60px !important;
  }*/
  .ui.grid.t4-banner-info {
    color: #9190a1 !important;
    margin-top: initial !important;
    height: auto !important;
  }
  .ui.grid.t4-banner-info h1.t4-school-name {
    padding: 0px 10px;
}
  .ui.grid.t4-banner-info .t4-hide {
    display: none !important;
  }
  .t4-content-wrapper {
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: 100% !important;
    top: 0px !important;
}
.ui.container.t4-survey-wrapper {
  margin: 0px !important;
}
  .ui.table td.six.wide, .ui.table th.six.wide {
    width: 100% !important;
}
  .t4-mcq-checkbox .ui.checkbox {
    width: 50% !important;
  }
  .t4-mcq-checkbox {
    padding-inline-start: 1px !important;
}
.t4-btn {
  padding: 10px 40px !important;
}

/*Flip Phone*/
.flip-phone-viewer {
  background-color: white !important;
}
table.ui.stackable.striped.table.t4-likert-options.t4-likert-head {
  padding-right: 20px !important;
}
.t4-questions > div:first-child > .flip-phone-viewer > div.flip-phone, div.quest-type2:first-of-type > .flip-phone-viewer div.flip-phone, 
div:not(.quest-type2) + .quest-type2 .flip-phone-viewer div.flip-phone {
  display: initial !important;
}
.t4-likert-head .emojie-option .ui.radio.checkbox .box:before, .t4-likert-head .emojie-option .ui.radio.checkbox label:before {
  display: initial !important;
}
.t4-likert ul.t4-likert-choices {
    padding-left: 0px !important;
}
.likert-main-header .t4-likert-options .likert-emo td {
    right: 0px;
    width: 100% !important;
    position: unset;
    float: right;
    direction: rtl;
}
.likert-main-header .t4-likert-options tr.likert-emo .emojie-option .ui.radio.checkbox label:before {
  right: 0px;
}
.t4-likert-head ul.t4-likert-choices {
  padding-right: 0px !important;
}
.t4-likert-head td {
  width: 100% !important;
}
.ui.table:not(.unstackable) tr.likert-emo>td {
  display: inline-block !important;
}
.ui.table:not(.unstackable) tr.likert-emo>td ul.t4-likert-choices li.emojie-option {
  width: 100% !important;
}
.ui.table.t4-likert-head:not(.unstackable) tr.likert-emo th {
  display: none;
}
table.t4-likert-head tr.likert-emo, .ui.table.t4-likert-head:not(.unstackable) tr {
  margin-bottom: 10px !important;
  border-bottom: 0px !important;
  box-shadow: initial !important;
  padding: 0px;
  display: grid !important;
  float: right;
  text-align: right;
  width: 100%;
}
.t4-likert-head .ui.radio.checkbox .box, .t4-likert-head .ui.radio.checkbox label {
  padding-left: 0px !important;
  padding-right: 3em !important;
    padding-top: 11px;
}


.t4-likert-options.t4-likert-head tr.likert-emo td:nth-child(2) .emojie-option .ui.radio.checkbox .box:before, .t4-likert-options.t4-likert-head tr.likert-emo td:nth-child(2) .emojie-option .ui.radio.checkbox label:before
 {
  /*background-image: url(/public/assets/worst-active.svg) !important;
  border: none !important;
  background-color: transparent !important;
  width: 40px !important;
  height: 40px !important;*/
  background: url(/public/assets/likert-emojies.png)  no-repeat !important;
  background-position: -246px 0px !important;
  width: 40px;
  height: 40px;
  border: none !important;
}
.t4-likert-options.t4-likert-head tr.likert-emo td:nth-child(3) .emojie-option .ui.radio.checkbox .box:before, .t4-likert-options.t4-likert-head tr.likert-emo td:nth-child(3) .emojie-option .ui.radio.checkbox label:before
 {
  /*background-image: url(/public/assets/fine-active.svg) !important;
  border: none !important;
  background-color: transparent !important;
  width: 40px !important;
  height: 40px !important;*/
  background: url(/public/assets/likert-emojies.png)  no-repeat !important;
  background-position: -287px 0px !important;
  width: 40px;
  height: 40px;
  border: none !important;
}
.t4-likert-options.t4-likert-head tr.likert-emo td:nth-child(4) .emojie-option .ui.radio.checkbox .box:before, .t4-likert-options.t4-likert-head tr.likert-emo td:nth-child(4) .emojie-option .ui.radio.checkbox label:before
 {
  /*background-image: url(/public/assets/neutral-active.svg) !important;
  border: none !important;
  background-color: transparent !important;
  width: 40px !important;
  height: 40px !important;*/
  background: url(/public/assets/likert-emojies.png)  no-repeat !important;
  background-position: -369px 0px !important;
  width: 40px;
  height: 40px;
  border: none !important;
}
.t4-likert-options.t4-likert-head tr.likert-emo td:nth-child(5) .emojie-option .ui.radio.checkbox .box:before, .t4-likert-options.t4-likert-head tr.likert-emo td:nth-child(5) .emojie-option .ui.radio.checkbox label:before
 {
  /*background-image: url(/public/assets/good-active.svg) !important;
  border: none !important;
  background-color: transparent !important;
  width: 40px !important;
  height: 40px !important;*/
  background: url(/public/assets/likert-emojies.png)  no-repeat !important;
  background-position: -328px 0px !important;
  width: 40px;
  height: 40px;
  border: none !important;
}
.t4-likert-options.t4-likert-head tr.likert-emo td:nth-child(6) .emojie-option .ui.radio.checkbox .box:before, .t4-likert-options.t4-likert-head tr.likert-emo td:nth-child(6) .emojie-option .ui.radio.checkbox label:before
 {
  /*background-image: url(/public/assets/loveit-active.svg) !important;
  border: none !important;
  background-color: transparent !important;
  width: 40px !important;
  height: 40px !important;*/
  background: url(/public/assets/likert-emojies.png)  no-repeat !important;
  background-position: -41px 0px !important;
  width: 40px;
  height: 40px;
  border: none !important;
}


}




/***********Arabic CSS***********/
body .App.t4-content-wrapper .ar-lang {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
.App.t4-content-wrapper .ar-lang {
  direction: rtl !important;
}
.App.t4-content-wrapper .ar-lang .ui.t4-topnav-menu.menu {
  direction: ltr;
}
.App.t4-content-wrapper .ar-lang .t4-pager .five.wide.column {
  text-align: left !important;
}
.App.t4-content-wrapper .ar-lang .t4-action-btn .t4-btn-surv.prev {
  margin-right: initial !important;
  margin-left: 15px !important;
}
/*Radio buttons & Check boxes & Dropdowns*/
.App.t4-content-wrapper .ar-lang .ui.radio.checkbox .box:before, .App.t4-content-wrapper .ar-lang .ui.radio.checkbox label:before {
  left: initial !important;
  right: 0 !important;
}
.App.t4-content-wrapper .ar-lang .ui.checkbox input[type=checkbox], .App.t4-content-wrapper .ar-lang .ui.checkbox input[type=radio] {
  right: 0 !important;
  left: initial !important;
}
.App.t4-content-wrapper .ar-lang .ui.radio.checkbox .box:after, .App.t4-content-wrapper .ar-lang .ui.radio.checkbox label:after {
  left: initial !important;
  right: 0 !important;
}
.App.t4-content-wrapper .ar-lang .ui.radio.checkbox .box, .App.t4-content-wrapper .ar-lang .ui.radio.checkbox label {
  padding-right: 1.85714em !important;
  padding-left: initial !important;
}
.App.t4-content-wrapper .ar-lang .ui.checkbox .box, .App.t4-content-wrapper .ar-lang .ui.checkbox label {
  padding-right: 1.85714em !important;
  padding-left: initial !important;
}
.App.t4-content-wrapper .ar-lang .ui.checkbox .box:before, .App.t4-content-wrapper .ar-lang .ui.checkbox label:before,
.App.t4-content-wrapper .ar-lang .ui.checkbox .box:after, .App.t4-content-wrapper .ar-lang .ui.checkbox label:after {
  right: 0 !important;
  left: initial !important;
}
.App.t4-content-wrapper .ar-lang .ui.selection.dropdown {
  padding: .78571429em 1em .78571429em 2.1em !important;
  text-align: right !important;
}
.App.t4-content-wrapper .ar-lang .ui.selection.dropdown>.delete.icon, 
.App.t4-content-wrapper .ar-lang .ui.selection.dropdown>.dropdown.icon, 
.App.t4-content-wrapper .ar-lang .ui.selection.dropdown>.search.icon {
    left: 1em !important;
    right: initial !important;
}
/*Gender Options*/
.United.Arab.Emirates .ui.radio.checkbox.\32 9, .Afghanistan .ui.radio.checkbox.\32 9,
.Algeria .ui.radio.checkbox.\32 9, .Angola .ui.radio.checkbox.\32 9,
.Azerbaijan .ui.radio.checkbox.\32 9, .Bahrain .ui.radio.checkbox.\32 9,
.Benin .ui.radio.checkbox.\32 9, .Botswana .ui.radio.checkbox.\32 9,
.Burkina.Faso .ui.radio.checkbox.\32 9, .Burundi .ui.radio.checkbox.\32 9,
.Cabo.Verde .ui.radio.checkbox.\32 9, .Cameroon .ui.radio.checkbox.\32 9,
.Central.African.Republic .ui.radio.checkbox.\32 9, .Chad .ui.radio.checkbox.\32 9,
.Comoros .ui.radio.checkbox.\32 9, .Congo .ui.radio.checkbox.\32 9,
.Cote.dIvoire .ui.radio.checkbox.\32 9, .Djibouti .ui.radio.checkbox.\32 9,
.DR.Congo .ui.radio.checkbox.\32 9, .Egypt .ui.radio.checkbox.\32 9,
.Equatorial.Guinea .ui.radio.checkbox.\32 9, .Eritrea .ui.radio.checkbox.\32 9,
.Eswatini .ui.radio.checkbox.\32 9, .Ethiopia .ui.radio.checkbox.\32 9,
.Gabon .ui.radio.checkbox.\32 9, .Gambia .ui.radio.checkbox.\32 9,
.Ghana .ui.radio.checkbox.\32 9, .Guinea .ui.radio.checkbox.\32 9,
.Guinea-Bissau .ui.radio.checkbox.\32 9,
.India .ui.radio.checkbox.\32 9, .Iran .ui.radio.checkbox.\32 9,
.Iraq .ui.radio.checkbox.\32 9, .Israel .ui.radio.checkbox.\32 9,
.Jordan .ui.radio.checkbox.\32 9, .Kenya .ui.radio.checkbox.\32 9,
.Kuwait .ui.radio.checkbox.\32 9, .Lebanon .ui.radio.checkbox.\32 9,
.Lesotho .ui.radio.checkbox.\32 9, .Liberia .ui.radio.checkbox.\32 9,
.Libya .ui.radio.checkbox.\32 9, .Madagascar .ui.radio.checkbox.\32 9,
.Malawi .ui.radio.checkbox.\32 9, .Mali .ui.radio.checkbox.\32 9,
.Mauritania .ui.radio.checkbox.\32 9, .Mauritius .ui.radio.checkbox.\32 9,
.Mayotte .ui.radio.checkbox.\32 9, .Morocco .ui.radio.checkbox.\32 9,
.Mozambique .ui.radio.checkbox.\32 9, .Namibia .ui.radio.checkbox.\32 9,
.Niger .ui.radio.checkbox.\32 9, .Nigeria .ui.radio.checkbox.\32 9,
.Oman .ui.radio.checkbox.\32 9, .Qatar .ui.radio.checkbox.\32 9,
.Réunion .ui.radio.checkbox.\32 9, .Rwanda .ui.radio.checkbox.\32 9,
.Saint.Helena .ui.radio.checkbox.\32 9, .Sao.Tome.and.Principe .ui.radio.checkbox.\32 9,
.Saudi.Arabia .ui.radio.checkbox.\32 9, .Senegal .ui.radio.checkbox.\32 9,
.Seychelles .ui.radio.checkbox.\32 9, .Sierra Leone .ui.radio.checkbox.\32 9,
.Somalia .ui.radio.checkbox.\32 9, .South.Sudan .ui.radio.checkbox.\32 9,
.State.of.Palestine .ui.radio.checkbox.\32 9, .Sudan .ui.radio.checkbox.\32 9,
.Syria .ui.radio.checkbox.\32 9, .Tanzania .ui.radio.checkbox.\32 9,
.Togo .ui.radio.checkbox.\32 9, .Tunisia .ui.radio.checkbox.\32 9,
.Turkey .ui.radio.checkbox.\32 9, .Uganda .ui.radio.checkbox.\32 9,
.Yemen .ui.radio.checkbox.\32 9, .Zambia .ui.radio.checkbox.\32 9,
.Zimbabwe .ui.radio.checkbox.\32 9
 {
  display: none;
}
.United.Arab.Emirates .ui.radio.checkbox.\33 56, .Afghanistan .ui.radio.checkbox.\33 56,
.Algeria .ui.radio.checkbox.\33 56, .Angola .ui.radio.checkbox.\33 56,
.Azerbaijan .ui.radio.checkbox.\33 56, .Bahrain .ui.radio.checkbox.\33 56,
.Benin .ui.radio.checkbox.\33 56, .Botswana .ui.radio.checkbox.\33 56,
.Burkina.Faso .ui.radio.checkbox.\33 56, .Burundi .ui.radio.checkbox.\33 56,
.Cabo.Verde .ui.radio.checkbox.\33 56, .Cameroon .ui.radio.checkbox.\33 56,
.Central.African.Republic .ui.radio.checkbox.\33 56, .Chad .ui.radio.checkbox.\33 56,
.Comoros .ui.radio.checkbox.\33 56, .Congo .ui.radio.checkbox.\33 56,
.Cote.dIvoire .ui.radio.checkbox.\33 56, .Djibouti .ui.radio.checkbox.\33 56,
.DR.Congo .ui.radio.checkbox.\33 56, .Egypt .ui.radio.checkbox.\33 56,
.Equatorial.Guinea .ui.radio.checkbox.\33 56, .Eritrea .ui.radio.checkbox.\33 56,
.Eswatini .ui.radio.checkbox.\33 56, .Ethiopia .ui.radio.checkbox.\33 56,
.Gabon .ui.radio.checkbox.\33 56, .Gambia .ui.radio.checkbox.\33 56,
.Ghana .ui.radio.checkbox.\33 56, .Guinea .ui.radio.checkbox.\33 56,
.Guinea-Bissau .ui.radio.checkbox.\33 56,
.India .ui.radio.checkbox.\33 56, .Iran .ui.radio.checkbox.\33 56,
.Iraq .ui.radio.checkbox.\33 56, .Israel .ui.radio.checkbox.\33 56,
.Jordan .ui.radio.checkbox.\33 56, .Kenya .ui.radio.checkbox.\33 56,
.Kuwait .ui.radio.checkbox.\33 56, .Lebanon .ui.radio.checkbox.\33 56,
.Lesotho .ui.radio.checkbox.\33 56, .Liberia .ui.radio.checkbox.\33 56,
.Libya .ui.radio.checkbox.\33 56, .Madagascar .ui.radio.checkbox.\33 56,
.Malawi .ui.radio.checkbox.\33 56, .Mali .ui.radio.checkbox.\33 56,
.Mauritania .ui.radio.checkbox.\33 56, .Mauritius .ui.radio.checkbox.\33 56,
.Mayotte .ui.radio.checkbox.\33 56, .Morocco .ui.radio.checkbox.\33 56,
.Mozambique .ui.radio.checkbox.\33 56, .Namibia .ui.radio.checkbox.\33 56,
.Niger .ui.radio.checkbox.\33 56, .Nigeria .ui.radio.checkbox.\33 56,
.Oman .ui.radio.checkbox.\33 56, .Qatar .ui.radio.checkbox.\33 56,
.Réunion .ui.radio.checkbox.\33 56, .Rwanda .ui.radio.checkbox.\33 56,
.Saint.Helena .ui.radio.checkbox.\33 56, .Sao.Tome.and.Principe .ui.radio.checkbox.\33 56,
.Saudi.Arabia .ui.radio.checkbox.\33 56, .Senegal .ui.radio.checkbox.\33 56,
.Seychelles .ui.radio.checkbox.\33 56, .Sierra Leone .ui.radio.checkbox.\33 56,
.Somalia .ui.radio.checkbox.\33 56, .South.Sudan .ui.radio.checkbox.\33 56,
.State.of.Palestine .ui.radio.checkbox.\33 56, .Sudan .ui.radio.checkbox.\33 56,
.Syria .ui.radio.checkbox.\33 56, .Tanzania .ui.radio.checkbox.\33 56,
.Togo .ui.radio.checkbox.\33 56, .Tunisia .ui.radio.checkbox.\33 56,
.Turkey .ui.radio.checkbox.\33 56, .Uganda .ui.radio.checkbox.\33 56,
.Yemen .ui.radio.checkbox.\33 56, .Zambia .ui.radio.checkbox.\33 56,
.Zimbabwe .ui.radio.checkbox.\33 56
 {
  display: none;
}
/*.App.t4-content-wrapper .ar-lang .ui.dropdown .menu>.item, .App.t4-content-wrapper .ar-lang .ui.dropdown>.text {
  text-align: right !important;
}*/
/*Likert CSS*/
.App.t4-content-wrapper .ar-lang tr.likert-emo {
  text-align: right !important;
}
.App.t4-content-wrapper .ar-lang .t4-likert-options tr td:first-child {
  text-align: right !important;
}
.App.t4-content-wrapper .ar-lang .t4-likert ul.t4-likert-choices .ui.radio.checkbox label {
  padding-right: 0.85714em !important;
}
.App.t4-content-wrapper .ar-lang .t4-likert ul.t4-likert-choices label {
  font-size: 0.8em !important;
}
/*Text Questions*/
.App.t4-content-wrapper .ar-lang .t4-input .ui.input.t4-input-txt {
  padding-right: 24px !important;
  padding-left: initial !important;
  width: 90%;
  margin-top: 1em;
}
.App.t4-content-wrapper .ar-lang .ui.input>input {
  text-align: right !important;
}
.t4-action-ddl {
  float: right;
}
/*Buttons*/
.t4-sticky-btns button.ui.icon.button, .t4-sticky-btns button.ui.button, .t4-action-btns button.ui.button, .t4-mng-btns button.ui.button, .t4-form-btns button.ui.button  {
  background-color: transparent !important;
  color: #12133E !important;
}
.t4-action-btns button.ui.button.t4-btn {
  color: #fff !important;
}
.t4-action-btns button.ui.button.t4-btn.basic {
  color: #12133E !important;
}
.t4-sticky-btns button.ui.icon.button:hover, .t4-action-btns button.ui.button:hover, .t4-mng-btns button.ui.button:hover, .t4-form-btns button.ui.button:hover {
  background-color: #e0e1e2 !important;
}
i.t4-email-error-icn:before, i.t4-email-error-icn {
  content: "";
  background-image: url(/public/assets/t4-email-error.svg) !important;
  width: 28px !important;
  height: 19px !important;
  vertical-align: sub;
  margin-right: 5px !important;
}
.ui.t4-error-msg.message {
  font-size: .92857143em !important;
}
i.user.icon.t4-custom-icn {
  color: #12133E !important;
  margin-left: 6px;
    margin-right: 12px !important;
    font-size: 1.2em !important;
}
i.t4-import-icn:before, i.t4-import-icn {
  content: "";
  background-image: url(/public/assets/t4-import.svg) !important;
  width: 18px !important;
  height: 16px !important;
  vertical-align: sub;
}
/*i.t4-clear-icn:before, i.t4-clear-icn {
  content: "";
  background-image: url(/public/assets/t4-clear.svg) !important;
  width: 16px !important;
  height: 16px !important;
  vertical-align: sub;
}*/
button.ui.button.t4-clear-icn, button.ui.button.t4-clear-icn i {
  background: transparent;
  color: #12133E !important;
}
.ui.dropdown .menu>.item.t4-clear-item {
  padding: 0px !important;
}
.ui.dropdown .menu>.t4-filter-content-scroll>.item {
  position: relative;
  cursor: pointer;
  display: block;
  border: none;
  height: auto;
  text-align: left;
  border-top: none;
  line-height: 1em;
  color: rgba(0,0,0,.87);
  padding: .78571429rem 1.14285714rem!important;
  font-size: 1rem;
  text-transform: none;
  font-weight: 400;
  box-shadow: none;
  -webkit-touch-callout: none;
}
.t4-filter-content-scroll {
  overflow: auto;
  max-height: 200px;
}
i.t4-co-icn:before, i.t4-co-icn {
  content: "";
  background-image: url(/public/assets/t4-admins.svg) !important;
  width: 18px !important;
  height: 16px !important;
  vertical-align: sub;
}
i.t4-staff-icn:before, i.t4-staff-icn {
  content: "";
  background-image: url(/public/assets/t4-persons.svg) !important;
  width: 25px !important;
  height: 25px !important;
  vertical-align: sub;
}
.t4-mng-icon-btns i.t4-staff-icn:before, .t4-mng-icon-btns i.t4-staff-icn {
  width: 18px !important;
  height: 18px !important;
}
i.t4-waves-icn:before, i.t4-waves-icn {
  content: "";
  background-image: url(/public/assets/t4-waves.svg);
  width: 25px !important;
  height: 25px !important;
  vertical-align: sub;
}
i.t4-card-icn.t4-waves-icn.icon:hover {
  content: "";
  background-image: url(/public/assets/t4-waves-active.svg) !important;
  width: 25px !important;
  height: 25px !important;
}
.t4-mng-icon-btns i.t4-waves-icn:before, .t4-mng-icon-btns i.t4-waves-icn {
  width: 18px !important;
  height: 18px !important;
}
span.t4-multi:nth-of-type(n + 2):not(:last-child)::after {
  content: ", ";
}
/*Pop Ups*/
.t4-popup.t4-reset-popup {
  max-width: 500px !important;
}
.t4-actions.t4-popup-btns {
  padding-top: 20px;
  float: right;
  min-height: 70px;
}
.t4-actions.t4-popup-btns button.ui.blue.button.t4-btn-active {
  margin-right: 0px !important;
}
i.filter.warn-sign.icon:after {
  content: "\f12a";
  padding-left: 22px !important;
    padding-bottom: 22px !important;
    height: 16px !important;
    vertical-align: sub;
    color: #E41E26;
    float: right;
    font-weight: lighter !important;
    font-size: 13px !important;
}
/*----Progress Steps*----*/
.ui.rating.progress-steps .icon {
  background: 0 0;
  color: rgba(0, 0, 0, .85);
  margin-right: 5px !important;
}
/*.ui.rating.progress-steps .active.icon {
  background-image: url(/public/assets/step-red.svg) !important;
}*/
.ui.rating.progress-steps .active.icon:before {
  background-image: url(/public/assets/step-red.svg) !important;
  content: none;
}
.ui.rating.progress-steps .icon:before {
  background-image: url(/public/assets/step-green.svg) !important;
  content: none;
  width: 23px;
  height: 22px;
  border: none !important;
}
.ui.rating.progress-steps .icon {
  background-image: url(/public/assets/step-off.svg) !important;
  width: 23px;
  height: 22px;
  border: none !important;
}
.ui.rating.progress-steps .active.icon:first-child {
  background-image: url(/public/assets/step-red.svg) !important;
}
.ui.rating.progress-steps .active.icon:nth-child(2) {
  background-image: url(/public/assets/step-orange.svg) !important;
}
.ui.rating.progress-steps .active.icon:nth-child(3) {
  background-image: url(/public/assets/step-yellow.svg) !important;
}
.ui.rating.progress-steps .active.icon:nth-child(4) {
  background-image: url(/public/assets/step-green.svg) !important;
}
/*---Waves---*/
span.t4-wave-toggle {
  font-weight: 700;
  margin-left: 10px;
}
.t4-wave-toggle .ui.fitted.toggle.checkbox {
  margin-left: 8px;
  vertical-align: sub;
}
.ui.buttons .or.t4-btn-mid:before {
  color: #fff !important;
  background-color: transparent !important;
}
.ui.buttons .or.t4-btn-mid {
  width: 0px !important;
}
/*button.ui.button.t4-btn-combo-lft, button.ui.button.t4-btn-combo-rt {
  background-color: #12133e !important;
  color: #fff !important;
}*/
.ui.basic.blue.button.t4-btn-combo, .ui.basic.blue.buttons .button.t4-btn-combo
{
  box-shadow: 0 0 0 1px #12133e inset !important;
  color: #12133e !important;
}
.ui.basic.blue.button.t4-btn-combo:hover, .ui.basic.blue.buttons .button.t4-btn-combo:hover
{
  box-shadow: 0 0 0 1px #12133e inset !important;
  background-color: #12133e !important;
  color: #fff !important;
}
.ui.label.t4-wave-lbl {
  background-color: #4054b2 !important;
  color: #fff !important;
}
/*---End of Waves---*/